import {Component, inject, Input} from '@angular/core';
import {ImageField, KeyTextField, NumberField, RichTextField, Slice, TitleField} from "@prismicio/client";
import {HTMLMapSerializer} from "@prismicio/helpers";
import {RenderHtmlPipe} from "../render-html/render-html.pipe";
import {RouterLink} from "@angular/router";
import {AosDirective} from "../../aos/aos.directive";
import {NgForOf, NgIf} from "@angular/common";
import {ApplicationModalService} from "../../application-modal/application-modal.service";

@Component({
  selector: 'app-team-slice',
  standalone: true,
    imports: [
        RenderHtmlPipe,
        RouterLink,
        AosDirective,
        NgForOf,
        NgIf
    ],
  templateUrl: './team-slice.component.html',
  styleUrl: './team-slice.component.scss'
})
export class TeamSliceComponent {

    @Input() body!: TeamSlice;

    private applicationModalService =inject(ApplicationModalService);

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        heading3: ({ children }) => `<p class="color-white text-size-l mb-0">${children}</p>`
    };

    openApplicationModal(): void {
        this.applicationModalService.openModal();
    }
}

export type TeamSlice = Slice<
    'team',
    {
        heading: TitleField;
        title: RichTextField;
        cta_title: KeyTextField;
        cta_button_title: KeyTextField;
        cta_position: NumberField;
    },
    Member
>;

export type Member = {
    image_primary: ImageField;
    name: TitleField;
    role: KeyTextField;
    description: KeyTextField;
};
