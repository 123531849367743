import { Pipe, PipeTransform } from '@angular/core';
import { LexiconSliceItem } from './lexicon-slice.component';

@Pipe({
    standalone: true,
    name: 'lexiconItemFilter'
})
export class LexiconItemFilterPipe implements PipeTransform {
    transform(lexiconSliceItems: LexiconSliceItem[], searchTerm: string): LexiconSliceItem[] {
        if (searchTerm.length === 0) {
            return lexiconSliceItems;
        }

        return lexiconSliceItems.filter((lexiconSliceItem) => {
            return lexiconSliceItem.article.data?.title?.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }
}
