<div class="container py-5 py-lg-6">
    <div class="row">
        <ng-container *ngFor="let teamMember of body.items; let index = index">
            <div class="col-md-6 col-xl-4 mt-4" appAos>
                <div class="wrapper h-100">
                    <div class="image-container h-100">
                        <img
                            [src]="teamMember.image_primary.url"
                            [alt]="teamMember.image_primary.alt"
                            [title]="teamMember.image_primary.alt"
                            class="w-100 card-image h-100 object-fit-cover"
                        />
                        <div class="overlay">
                            <div [innerHTML]="teamMember.name | renderHtml : htmlSerializer"></div>
                            <p class="color-white mt-0">{{ teamMember.role }}</p>
                        </div>
                    </div>
                    <div class="description">
                        <p class="color-white">
                            {{ teamMember.description }}
                        </p>
                    </div>
                </div>
            </div>

            <!-- Request cards -->
            <div class="col-md-6 col-xl-4 mt-4" appAos *ngIf="index === ((body?.primary?.cta_position -2) || 6)">
                <div class="card background-pink px-4 pt-6 pb-5 position-relative h-100 d-flex flex-column justify-content-between">
                    <img class="position-absolute end-0 top-0 icon-big me-4 mt-4" src="assets/images/plus-white.svg" alt="Plus">
                    <p class="text-size-l color-white mb-0">{{body?.primary?.cta_title}}</p>
                    <button class="btn btn-black w-100 mt-4" (click)="openApplicationModal()">{{body?.primary?.cta_button_title}}</button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
