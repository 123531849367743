import {Component, input} from '@angular/core';
import {RichTextField, Slice} from "@prismicio/client";
import {RenderHtmlPipe} from "../render-html/render-html.pipe";
import {AosDirective} from "../../aos/aos.directive";

@Component({
  selector: 'app-speech-bubble-slice',
  standalone: true,
    imports: [
        RenderHtmlPipe,
        AosDirective
    ],
  templateUrl: './speech-bubble-slice.component.html',
  styleUrl: './speech-bubble-slice.component.scss'
})
export class SpeechBubbleSliceComponent {
    body = input.required<SpeechBubbleSlice>();
}

export type SpeechBubbleSlice = Slice<
    'speech_bubble',
    {
        title: RichTextField;
    },
    never
>;
