<div class="container py-5 py-lg-6">
    <div class="row mb-lg-5">
        <div class="col-12">
            <div class="form-floating">
                <input
                    type="text"
                    class="form-control"
                    id="search"
                    placeholder="Lexikon durchsuchen..."
                    (keyup)="searchTermChanged($event.target.value)"
                />
                <label for="search">Lexikon durchsuchen...</label>
            </div>
        </div>
        <div class="col-12 mb-5 my-lg-5">
            <div class="shadow-sm p-4 px-lg-5">
                <ul class="list-unstyled mb-0 d-lg-flex justify-content-lg-between">
                    <li *ngFor="let chapter of chapters" class="mt-3 mt-lg-0">
                        <a routerLink="." [fragment]="chapter.letter">{{ chapter.letter }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row" [id]="chapter.letter" *ngFor="let chapter of chapters; let i = index">
        <ng-container *ngIf="chapter.items | lexiconItemFilter : searchTerm as filteredLexiconItems">
            <div class="col-12" *ngIf="filteredLexiconItems.length > 0">
                <p class="text-size-xl color-black">{{ chapter.letter }}</p>
            </div>
            <div class="col-md-6 col-xl-4 pb-5" *ngFor="let lexiconItem of filteredLexiconItems">
                <a [routerLink]="lexiconItem.article.uid" [title]="lexiconItem.article.data.title">
                    <div class="shadow-sm h-100 px-4 py-5 px-lg-5">
                        <p class="text-size-l">{{ lexiconItem.article.data.emoji }}</p>
                        <h2 class="text-size-m color-black">{{ lexiconItem.article.data.title }}</h2>
                        <p>{{ lexiconItem.article.data.short_description }}</p>
                    </div>
                </a>
            </div>
            <div class="col-12 text-center" *ngIf="filteredLexiconItems.length === 0 && i === 0">
                <p class="fw-bold color-black">Keine passenden Treffer</p>
            </div>
        </ng-container>
    </div>
</div>
