import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GlobalContent, GlobalContentService } from '../../../../services/global-content.service';
import { AosDirective } from '../../aos/aos.directive';

@Component({
    selector: 'app-trust-banner-slice',
    templateUrl: './trust-banner-slice.component.html',
    styleUrls: ['./trust-banner-slice.component.scss'],
    standalone: true,
    imports: [AosDirective]
})
export class TrustBannerSliceComponent implements OnInit {
    @ViewChild('scroller', { static: false }) scroller!: ElementRef;
    globalContent?: GlobalContent | null;

    constructor(private globalContentService: GlobalContentService) {}

    ngOnInit() {
        this.globalContentService.getGlobalContent().subscribe({
            next: (res) => {
                this.globalContent = res;
            }
        });
    }
}
