import {Component, inject, Input} from '@angular/core';
import {ContentRelationshipField, PrismicDocument, Slice} from "@prismicio/client";
import {LexiconArticle} from "../../../lexicon/lexicon.service";
import {HTMLMapSerializer} from "@prismicio/helpers";
import {RouterLink} from "@angular/router";
import {NgForOf, NgIf} from "@angular/common";
import {LexiconItemFilterPipe} from "./lexicon-item-filter.pipe";

@Component({
  selector: 'app-lexicon-slice',
  standalone: true,
    imports: [
        RouterLink,
        NgIf,
        NgForOf,
        LexiconItemFilterPipe
    ],
  templateUrl: './lexicon-slice.component.html',
  styleUrl: './lexicon-slice.component.scss'
})
export class LexiconSliceComponent {

    @Input() body?: LexiconSlice;
    @Input() path = '';

    searchTerm = '';
    chapters: Chapter[] = [];

    constructor() {}

    ngOnInit(): void {
        this.setupChapters();
    }

    private setupChapters() {
        for (const lexiconItem of this.body!.items) {
            const firstLetter = lexiconItem.article?.data?.title?.charAt(0).toUpperCase();

            if (!firstLetter) {
                return;
            }

            const foundIndex = this.chapters.findIndex((chapter) => chapter.letter == firstLetter);

            if (foundIndex >= 0) {
                // Update
                const chapter = this.chapters[foundIndex];
                chapter.items.push(lexiconItem);
                this.chapters[foundIndex] = this.chapters[foundIndex];
            } else {
                // Create
                this.chapters.push({
                    letter: firstLetter,
                    items: [lexiconItem]
                });
            }
        }

        this.chapters.sort((a, b) => a.letter.localeCompare(b.letter));
    }

    htmlSerializer: HTMLMapSerializer = {
        heading2: ({ children }) => `<h2 class="text-size-m">${children}</h2>`
    };

    searchTermChanged(searchTerm: string) {
        this.searchTerm = searchTerm;
    }
}

export type LexiconSlice = Slice<'lexicon', never, LexiconSliceItem>;

export type LexiconSliceItem = {
    article: ContentRelationshipField<LexiconArticle> | any;
};

export interface Chapter {
    letter: string;
    items: LexiconSliceItem[];
}
