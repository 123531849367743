<div class="container pb-5 pb-lg-6">
    <div class="row">
        @for (job of body?.items; track job) {
        <app-job-item [job]="job?.job?.data" [uid]="job?.job?.uid"></app-job-item>
        } @if (body?.items.length === 0) {
        <div class="row mt-5">
            <div class="col-12 text-center">
                <img src="./assets/images/no-data.svg" alt="" class="w-25" />
                <p>
                    Aktuell sind keine Qualifikationen verfügbar ☹️
                </p>
            </div>
        </div>
        }
    </div>
</div>
