<div class="container mb-5 mb-lg-6">
    <div class="row">
        @for (company of body.items; track company; let idx = $index) {
        <div class="col-lg-4 d-flex my-4 my-lg-5" [class.d-none]="body?.primary?.hide_items && !expandItems && idx > 2" appAos>
            <div class="shadow p-5 d-flex flex-column justify-content-between">
                <div>
                    <img [src]="company.logo.url" [alt]="company.logo.alt" class="logo mb-5" />
                    <div [innerHTML]="company.description | renderHtml"></div>
                </div>
                <app-link [link]="company.link" [cssClass]="'btn btn-black'">
                    Jetzt ansehen
                </app-link>
            </div>
        </div>
        }

        <!--
    <div
      class="col-lg-8 d-flex mt-5 mt-lg-6"
      appAos
      >
      <div class="shadow h-100 p-5">
        <div class="row h-100">
          <div class="col-lg-6 order-lg-1 d-flex align-items-center">
            <img
              src="./assets/images/swinging_pure.svg"
              alt=""
              class="w-100 mb-5 mb-lg-0"
              />
          </div>
          <div class="col-lg-6 order-lg-0 d-flex flex-column">
            <p class="heading">{{ 'PARTNER_COMPANIES_SLICE.COMING_SOON.HEADING' | translate }}</p>
            <h3>{{ 'PARTNER_COMPANIES_SLICE.COMING_SOON.TITLE' | translate }}</h3>
            <div class="mt-auto">
              <p>
                {{ 'PARTNER_COMPANIES_SLICE.COMING_SOON.DESCRIPTION' | translate }}
              </p>
              <a
                [routerLink]="'PARTNER_COMPANIES_SLICE.COMING_SOON.LINK' | translate"
                class="btn btn-black"
                >{{ 'PARTNER_COMPANIES_SLICE.COMING_SOON.BUTTON_TITLE' | translate }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    @if (body?.primary?.hide_items && !expandItems && body?.items?.length > 2) {
    <div class="row mt-4" appAos>
        <div class="col text-center">
            <button class="btn btn-black" (click)="showAll()">Mehr ansehen</button>
        </div>
    </div>
    }
</div>
